import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { restrictedZonesApi } from "../../../api/api";
import { FormButton } from "../../../common/utils/stylings";
import { useTranslation } from "react-i18next";

const PointWithRadiusForm = ({ ats, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name_required")),
    latitude: Yup.string()
      .matches(/^\d+\.?\d*$/, t("only_numbers_and_decimals"))
      .required(t("latitude_required")),
    longitude: Yup.string()
      .matches(/^\d+\.?\d*$/, t("only_numbers_and_decimals"))
      .required(t("longitude_required")),
    radius: Yup.string()
      .matches(/^[0-9]*$/, t("only_numbers"))
      .required(t("radius_required")),
  });

  const onPostRestrictedZone = (fields, ats) => {
    restrictedZonesApi
      .postRestrictedZone(fields, ats)
      .then((res) => {
        handleOpenSnackbar("success", t("restricted_area_created"));
        history.goBack();
      })
      .catch((err) => {
        handleOpenSnackbar("error", t("create_restricted_area_failed"));
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      latitude: "",
      longitude: "",
      radius: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onPostRestrictedZone(fields, ats);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <TextField
          variant="standard"
          fullWidth
          id="name"
          name="name"
          label="Namn"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            variant="standard"
            fullWidth
            style={{ width: "49%" }}
            id="latitude"
            name="latitude"
            label={t("latitude")}
            value={formik.values.latitude}
            onChange={formik.handleChange}
            error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            helperText={formik.touched.latitude && formik.errors.latitude}
          />
          <TextField
            variant="standard"
            fullWidth
            style={{ width: "49%" }}
            id="longitude"
            name="longitude"
            label={t("longitude")}
            value={formik.values.longitude}
            onChange={formik.handleChange}
            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            helperText={formik.touched.longitude && formik.errors.longitude}
          />
        </div>
        <TextField
          variant="standard"
          fullWidth
          id="radius"
          name="radius"
          label={t("radius_with_unit")}
          value={formik.values.radius}
          onChange={formik.handleChange}
          error={formik.touched.radius && Boolean(formik.errors.radius)}
          helperText={formik.touched.radius && formik.errors.radius}
        />
      </div>
      <FormButton type="submit" variant={"contained"}>
        {t("create_restricted_area")}
      </FormButton>
    </form>
  );
};

export default PointWithRadiusForm;
