import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { FormButton } from "../../common/utils/stylings";
import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
  }, []);

  return (
    <div className="narrow-container">
      <div className="item">
        <div>
          <div className="page-title">{t("unauthorized")}</div>
        </div>
        <FormButton
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/");
          }}
        >
          {t("go_back")}
        </FormButton>
      </div>
    </div>
  );
};

export default Unauthorized;
