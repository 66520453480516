import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import { FormButton } from "../../common/utils/stylings";
import { accountApi, countriesApi } from "../../api/api";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Paper from "@mui/material/Paper";

const Register = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [countries, setCountries] = useState([]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email_invalid")).required(t("email_required")),
    name: Yup.string()
      .matches(/^[a-zåäöA-ZÅÄÖ\s]+$/, t("only_letters_and_whitespace"))
      .required(t("name_required")),
    phoneNumber: Yup.string().required(t("phone_nr_required")),
    password: Yup.string().min(8, t("password_required_length")).required(t("password_required")),
    confirmPassword: Yup.string().required(t("password_confirm_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      country: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      toggle: false,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      accountApi
        .register(fields)
        .then((res) => {
          handleOpenSnackbar("success", t("registration_success"));
          history.push(`/register-success?email=${fields.email}`);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          console.log(err.response);
          console.log(err.response.data);
          console.log(err.response.data.message);

          handleOpenSnackbar("error", t("registration_failure"));
        });
    },
  });

  useEffect(() => {
    countriesApi
      .getCountries()
      .then((res) => {
        setCountries(res.data);
        let c = "";
        try {
          const cookie = document.cookie.split("; ");
          const language = cookie.filter((item) => {
            return item.includes("LANG");
          });
          if (language.length > 0) {
            const cookieLang = language[0].split("=")[1];
            const get639 = cookieLang.split("-")[0];
            c = res.data.find((c) => c.iso639 === get639);
          } else {
            const browserLang = navigator.language || navigator.userLanguage;
            c = res.data.find((c) => c.iso639 === browserLang);
          }
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        } catch (err) {
          c = res.data.find((c) => c.iso639 === "sv");
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   countriesApi
  //     .getCountries()
  //     .then((res) => {
  //       setCountries(res.data);
  //       let c = "";
  //       const browserLang = navigator.language || navigator.userLanguage;
  //       try {
  //         c = res.data.find((c) => c.iso639 === browserLang);
  //         formik.setFieldValue("country", c);
  //         formik.setFieldValue("phoneNumber", c.phonePrefix);
  //       } catch (err) {
  //         const cookie = document.cookie.split("; ");
  //         const language = cookie.filter((item) => {
  //           return item.includes("LANG");
  //         });
  //         if (language.length > 0) {
  //           const cookieLang = language[0].split("=")[1];
  //           const get639 = cookieLang.split("-")[0];
  //           c = res.data.find((c) => c.iso639 === get639);
  //         } else {
  //           c = res.data.find((c) => c.iso639 === "en");
  //         }
  //         formik.setFieldValue("country", c);
  //         formik.setFieldValue("phoneNumber", c.phonePrefix);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const handleChange = (event) => {
    formik.setFieldValue("country", event.target.value);
    if (!event.target.value) formik.setFieldValue("phoneNumber", "");
    else {
      const c = countries.find((c) => c.iso3166 === event.target.value);
      formik.setFieldValue("phoneNumber", c.phonePrefix);
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <Paper sx={{ padding: 1 }} elevation={3}>
            <BackButtonWithTitle title={t("create_new_account")} />
            <div className="item">
              <TextField
                variant="standard"
                fullWidth
                id="email"
                name="email"
                label={t("email")}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label={t("name")}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl sx={{ width: "70%", marginRight: 0 }} variant="standard">
                  <InputLabel shrink={true} id="ats-select">
                    {t("country_code")}
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.country}
                    onChange={handleChange}
                    inputProps={{
                      name: "ats",
                      id: "uncontrolled-native",
                    }}
                    id="ats-select"
                  >
                    {countries?.map((country) => {
                      return (
                        <option key={country.iso3166} value={country.iso3166}>
                          {t(`${country.iso3166}`)}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                <TextField
                  variant="standard"
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  label={t("phone_nr")}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </div>
              <TextField
                variant="standard"
                fullWidth
                id="password"
                name="password"
                label={t("password")}
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                variant="standard"
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label={t("confirm_password")}
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
              <div style={{ display: "flex" }}>
                <Checkbox sx={{ padding: 0 }} id="toggle" name="toggle" checked={formik.values.toggle} onChange={formik.handleChange} />
                <p style={{ fontSize: 14, paddingLeft: 5 }}>
                  {t("agree_terms")} <a href="/gdpr">{t("agree_terms2")}</a>
                </p>
              </div>
              <div>
                <FormButton disabled={!formik.values.toggle} variant="contained" color="primary" type="submit">
                  {t("create_account")}
                </FormButton>
              </div>
            </div>
          </Paper>
        </div>
      </form>
    </div>
  );
};

export default Register;
