import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../common/components/BackButton";
import { countryCodes } from "../../common/utils/countryCodes";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import { AccountContext } from "../../contexts/AccountContext";
import { FormButton } from "../../common/utils/stylings";
import { accountApi, countriesApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const CreateAtsAccount = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [countries, setCountries] = useState([]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email_invalid")).required(t("email_required")),
    name: Yup.string()
      .matches(/^[a-zåäöA-ZÅÄÖ\s]+$/, t("only_letters_and_whitespace"))
      .required(t("name_required")),
    country: Yup.object().nullable().required(t("country_code_required")),
    phoneNumber: Yup.string().required(t("phone_nr_required")),
    password: Yup.string().min(8, t("password_length")).required(t("password_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      country: "",
      phoneNumber: "",
      password: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onCreateAtsAccount(fields);
    },
  });

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 50);
  }, []);

  useEffect(() => {
    countriesApi
      .getCountries()
      .then((res) => {
        setCountries(res.data);
        let c = "";
        try {
          const cookie = document.cookie.split("; ");
          const language = cookie.filter((item) => {
            return item.includes("LANG");
          });
          if (language.length > 0) {
            const cookieLang = language[0].split("=")[1];
            const get639 = cookieLang.split("-")[0];
            c = res.data.find((c) => c.iso639 === get639);
          } else {
            const browserLang = navigator.language || navigator.userLanguage;
            c = res.data.find((c) => c.iso639 === browserLang);
          }
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        } catch (err) {
          c = res.data.find((c) => c.iso639 === "en");
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onCreateAtsAccount = (fields) => {
    accountApi
      .createAtsAccount(fields)
      .then((res) => {
        handleOpenSnackbar("success", res.data);
        history.goBack();
      })
      .catch((err) => {
        handleOpenSnackbar("error", t("registration_failure"));
      });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <BackButtonWithTitle title={t("create_new_ats_account")} />
          <div className="item">
            <TextField
              variant="standard"
              fullWidth
              id="email"
              name="email"
              label={t("email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              variant="standard"
              fullWidth
              id="name"
              name="name"
              label={t("name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Autocomplete
                id="country-select"
                style={{ width: "70%", marginRight: 0 }}
                options={countries}
                value={formik.values.country}
                onChange={(event, newValue) => {
                  formik.setFieldValue("country", newValue);
                  // focusPhoneNumber();

                  if (!newValue) formik.setFieldValue("phoneNumber", "");
                  else formik.setFieldValue("phoneNumber", newValue.phonePrefix);
                }}
                getOptionLabel={(option) => {
                  return t(`${option.iso3166}`);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {t(`${option.iso3166}`)} ({option.phonePrefix})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    type="text"
                    variant="standard"
                    label={t("country_code")}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />
              <TextField
                variant="standard"
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label={t("phone_nr")}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
            </div>
            {/* <TextField
              variant="standard"
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Telefonnummer"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            /> */}
            <TextField
              variant="standard"
              fullWidth
              id="password"
              name="password"
              label={t("password")}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Alert sx={{ marginTop: 1 }} severity="warning">
              {t("password_change_on_login")}
            </Alert>
            <FormButton type="submit" variant={"contained"}>
              {t("create_account")}
            </FormButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAtsAccount;
