import { useState, useEffect, useContext } from "react";
import { TableHead, TableRow, TableBody, TableContainer, Table } from "@mui/material";
import { MapContainer, TileLayer, Circle, useMap, Polygon } from "react-leaflet";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "../../common/hooks/hooks";
import { BackButton } from "../../common/components/BackButton";
import IconButton from "@mui/material/IconButton";
import { FormButton, StyledTableCell } from "../../common/utils/stylings";
import Pagination from "@mui/material/Pagination";
import { standardRequestsApi, atsApi, settingsApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const StandardRequests = ({ setSelected }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [pageSize, setPageSize] = useState(5);
  const id = useQuery().get("id");
  const [standardRequests, setStandardRequests] = useState();
  const [center, setCenter] = useState();
  const [airspace, setAirspace] = useState();
  const [highlightedZone, setHighLightedZone] = useState();
  const [name, setName] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    const retrieveStandardRequestData = () => {
      setIsPageLoading(true);

      settingsApi
        .getPageSize()
        .then((res) => {
          setPageSize(res.data);

          Promise.all([atsApi.getAts(id), standardRequestsApi.getStandardByAts(1, res.data, id)])
            .then(([at, st]) => {
              const pagination = JSON.parse(st.headers.pagination);
              setAirspace(at.data.airspace);
              setName(at.data.name);
              setCenter([at.data.lat, at.data.lng]);
              setTotalPages(pagination.TotalPages);
              setStandardRequests(st.data);
              setIsPageLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setError(err);
              setIsPageLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    retrieveStandardRequestData();
  }, []);

  function SetViewOnClick({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  const highlightZone = (request) => {
    if (request === highlightedZone) setHighLightedZone();
    else {
      setHighLightedZone(request);
      setCenter([request.area[0][0], request.area[0][1]]);
    }
  };

  const newPage = (p) => {
    setCurrPage(p);
    standardRequestsApi
      .getStandardByAts(p, pageSize, id)
      .then((res) => {
        const pagination = JSON.parse(res.headers.pagination);
        setTotalPages(pagination.TotalPages);
        setStandardRequests(res.data);
      })
      .catch((err) => console.log(err));
  };

  const trashStandardRequest = (zone) => {
    setIsLoading(true);
    standardRequestsApi.deleteStandardRequest(zone.id).then((res) => {
      standardRequestsApi
        .getStandardByAts(currPage, pageSize, id)
        .then((res) => {
          setStandardRequests(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const editStandardRequest = (e, request) => {
    e.stopPropagation();
    setSelected(request);
    history.push(`/edit-standard-request?id=${id}`);
  };

  if (error) {
    return <div className="standard-container">{error.toString()}</div>;
  }

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="narrow-container">
      <BackButtonWithTitle title={t("map_layers", { ats: name })} />
      <div className="item">
        <div className="map-container">
          <Map
            user={user}
            airspace={airspace}
            standardRequests={standardRequests}
            highlightedZone={highlightedZone}
            SetViewOnClick={SetViewOnClick}
            center={center}
          />
        </div>
      </div>
      <div className="item">
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
        <StandardRequestsTable
          standardRequests={standardRequests}
          highlightZone={highlightZone}
          trashStandardRequest={trashStandardRequest}
          editStandardRequest={editStandardRequest}
        />
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          shape="rounded"
          count={totalPages}
          page={currPage}
          onChange={(e, newValue) => {
            newPage(newValue);
          }}
        />
        <FormButton variant="contained" color="primary" onClick={() => history.push(`/create-standard-request?id=${id}`)}>
          {t("create_map_layer")}
        </FormButton>
      </div>
    </div>
  );
};

export default StandardRequests;

const Map = ({ SetViewOnClick, standardRequests, highlightedZone, center, airspace }) => {
  return (
    <MapContainer id="map" center={center} zoom={9} scrollWheelZoom={true}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      {highlightedZone && <Polygon key={highlightedZone.id} pathOptions={{ color: "red" }} positions={highlightedZone.area} opacity={1} />}
      {standardRequests?.map((zone) => {
        if (zone.area !== null) return <Polygon key={zone.id} pathOptions={{ color: "red" }} positions={zone.area} opacity={0} />;
        else
          return (
            <Circle key={zone.id} center={[zone.lat, zone.lng]} radius={zone.radius} fillColor="#FF0000" color="#FF0000" opacity={0} />
          );
      })}
      <SetViewOnClick center={center} />
      <Polygon pathOptions={{ color: "red" }} positions={airspace} fillOpacity={0} />
    </MapContainer>
  );
};

const StandardRequestsTable = ({ standardRequests, highlightZone, trashStandardRequest, editStandardRequest }) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t("name")}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {standardRequests?.map((request) => {
            return (
              <TableRow key={request.id} onClick={() => highlightZone(request)}>
                <StyledTableCell>{request.name}</StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      editStandardRequest(e, request);
                    }}
                    title={t("edit_map_layer")}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      trashStandardRequest(request);
                    }}
                    title={t("remove_map_layer")}
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
