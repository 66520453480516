import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export const ShowPhoneNumberModal = ({ show, handleClose, ats }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t("contact_tower")}</DialogTitle>
      <DialogContent>
        {ats && (
          <div>
            {t("request_permission")}
            <a href={`tel:${ats?.phoneNumber}`}>{ats?.phoneNumber}</a>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
