import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(228, 228, 228)",
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: window.innerWidth > 500 ? 14 : 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 500 ? 12 : 9,
    padding: 2,
  },
}));

export const StyledTableCellSelected = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: window.innerWidth > 500 ? 14 : 10,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#ADD8E6",
    fontSize: window.innerWidth > 500 ? 12 : 9,
    padding: 2,
  },
}));

export const StyledTableCellSecondary = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(228, 228, 228)",
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: window.innerWidth > 500 ? 14 : 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 500 ? 12 : 9,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 7,
    paddingBottom: 7,
  },
}));

export const FormButton = styled(Button)(({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
}));

export const StyledPaper = withStyles({
  root: {
    position: "relative",
  },
})(Paper);

export const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
  },
})(Backdrop);
