import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export const PreviewModal = ({ show, handleClose, preview }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent sx={{ width: "500px" }}>
        <Map preview={preview} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Map = ({ preview }) => {
  return (
    <MapContainer id="modal-map" center={[preview[0][0], preview[0][1]]} zoom={9}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      <Polygon pathOptions={{ color: "red" }} positions={preview} opacity={1} fillOpacity={0.5} />
    </MapContainer>
  );
};
