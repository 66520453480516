import React from "react";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const Gdpr = () => {
  const { t } = useTranslation();
  return (
    <div className="narrow-container">
      <BackButtonWithTitle title={t("personal_data_and_integrity")} />
      <div className="item">
        <div className="text-container">
          <div>
            <h3>{t("a_introduction")}</h3>
            <ol>
              <li>{t("a_1")}</li>
            </ol>
            <h3>{t("b_recognition")}</h3>
            <div>{t("b_1")}</div>
            <h3>{t("c_collecting_personal_information")}</h3>
            <div>
              {t("c_1")}
              <ol>
                <li>{t("c_1_1")}</li>
                <li>{t("c_1_2")}</li>
                <li>{t("c_1_3")}</li>
                <li>{t("c_1_4")}</li>
                <li>{t("c_1_5")}</li>
              </ol>
            </div>
            <h3>{t("d_usage_of_personal_information")}</h3>
            <div>
              {t("d_1")}
              <ol>
                <li>{t("d_1_1")}</li>
                <li>{t("d_1_2")}</li>
                <li>{t("d_1_3")}</li>
                <li>{t("d_1_4")}</li>
                <li>{t("d_1_5")}</li>
                <li>{t("d_1_6")}</li>
                <li>{t("d_1_7")}</li>
                <li>{t("d_1_8")}</li>
                <li>{t("d_1_9")}</li>
                <li>{t("d_1_10")}</li>
                <li>{t("d_1_11")}</li>
                <li>{t("d_1_12")}</li>
                <li>{t("d_1_13")}</li>
              </ol>
              {t("d_2_1")}
            </div>
            <h3>{t("e_disclosure_of_personal_information")}</h3>
            <div>
              {t("e_1")}
              <ol>
                <li>{t("e_1_1")}</li>
                <li>{t("e_1_2")}</li>
                <li>{t("e_1_3")}</li>
                <li>{t("e_1_4")}</li>
                <li>{t("e_1_5")}</li>
              </ol>
              {t("e_2")}
            </div>
            <h3>{t("f_international_data_transfer")}</h3>
            <div>
              <ol>
                <li>{t("f_1")}</li>
              </ol>
            </div>
            <h3>{t("g_preservation_of_personal_information")}</h3>{" "}
            <div>
              <ol>
                <li>{t("g_1")}</li>
                <li>{t("g_2")}</li>
                <li>
                  {t("g_3")}
                  <ol type="a">
                    <li>{t("g_3_1")}</li>
                    <li>{t("g_3_2")}</li>
                    <li>{t("g_3_3")}</li>
                  </ol>
                </li>
              </ol>
            </div>
            <h3>{t("h_security_of_personal_information")}</h3>{" "}
            <div>
              <ol>
                <li>{t("h_1")}</li>
                <li>{t("h_2")}</li>
                <li>{t("h_3")}</li>
                <li>{t("h_4")}</li>
                <li>{t("h_5")}</li>
              </ol>
            </div>
            <h3>{t("i_changes")}</h3> <div>{t("i_1")}</div>
            <h3>{t("j_your_rights")}</h3>{" "}
            <div>
              {t("j_1")}
              <ol>
                <li>{t("j_1_1")}</li> <li>{t("j_1_2")}</li>
              </ol>
              {t("j_2")}
            </div>
            <h3>{t("k_third_party_websites")}</h3> <div>{t("k_1")}</div>
            <h3>{t("l_update_of_information")}</h3> <div>{t("l_1")}</div>
            <h3>{t("m_cookies")}</h3> <div>{t("m_1")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gdpr;
