import React from "react";
import { useQuery } from "../../common/hooks/hooks";
import { FormButton } from "../../common/utils/stylings";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";

const ChangeEmailVerificationSent = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();

  const email = useQuery().get("email");
  const newEmail = useQuery().get("newEmail");

  const handleConfirmEmailResend = () => {
    accountApi
      .changeEmailConfirmationLink(email, newEmail)
      .then((res) => {
        handleOpenSnackbar("success", `${res.data}`);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar("error", `${err}`);
      });
  };
  return (
    <div className="narrow-container">
      <div className="item">
        <div>
          <big>{t("confirmation_email_sent")}</big>
        </div>
        <div>
          <FormButton onClick={handleConfirmEmailResend} variant="contained" color="primary">
            {t("send_again")}
          </FormButton>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmailVerificationSent;
