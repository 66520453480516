import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import { requestsApi } from "../../../api/api";
import { AccountContext } from "../../../contexts/AccountContext";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

export const ErasedToolbar = ({
  setErased,
  setCurrPageErased,
  setTotalPagesErased,
  search,
  setSearch,
  setIsLoading,
  setHighlightErased,
  pageSize,
}) => {
  const { user } = useContext(AccountContext);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (fields) => {
      setIsLoading(true);
      setSearch(fields.search);
      requestsApi
        .searchErasedByUser(1, pageSize, user.email, fields.search)
        .then((res) => {
          const pagination = JSON.parse(res.headers.pagination);
          setTotalPagesErased(pagination.TotalPages);
          setCurrPageErased(1);
          setErased(res.data);
          setHighlightErased();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
  });

  return (
    <form className="table-toolbar" onSubmit={formik.handleSubmit}>
      <div>
        {search && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            {t("search_results")} "{search}"{" "}
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => {
                requestsApi
                  .getErasedByUser(1, pageSize, user.email)
                  .then((res) => {
                    const pagination = JSON.parse(res.headers.pagination);
                    setTotalPagesErased(pagination.TotalPages);
                    setCurrPageErased(1);
                    setErased(res.data);
                    setSearch("");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
        )}
      </div>
      <TextField
        sx={{ width: window.innerWidth > 450 ? "40%" : "100%", marginBottom: 1, marginTop: 1 }}
        variant="outlined"
        size="small"
        id="search"
        name="search"
        value={formik.values.search}
        onChange={formik.handleChange}
        placeholder={t("search")}
        InputProps={{
          type: "search",
          startAdornment: <SearchIcon fontSize="medium" />,
        }}
      />
    </form>
  );
};
