import React, { useState, useEffect } from "react";
import { convertToLocal, latDecToDMS, lngDecToDMS, round } from "../../../common/utils/functions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { meterToFeetConversionRate } from "../../../common/utils/constants";

export const InfoModal = ({ show, handleClose, isLoading, selectedRequest }) => {
  const [elevation, setElevation] = useState(0);
  const [requestHeightSeaLevel, setRequestHeightSeaLevel] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedRequest) {
      setElevation(0);
      setRequestHeightSeaLevel(selectedRequest.height);
      fetch(`https://api.open-elevation.com/api/v1/lookup?locations=${selectedRequest.lat},${selectedRequest.lng}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.results[0].elevation) {
            setElevation(data.results[0].elevation);
            setRequestHeightSeaLevel(data.results[0].elevation + selectedRequest.height);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedRequest]);

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        {selectedRequest && (
          <>
            <ul>
              <div>
                <b>{t("created_by")}:</b>{" "}
                <li>
                  {t("name")}: {selectedRequest.appUser.displayName}
                </li>
                <li>
                  {t("phone")}: {selectedRequest.appUser.phoneNumber}
                </li>
                <li>
                  {t("email")}: {selectedRequest.appUser.email}
                </li>
              </div>
              <div>
                <br />
                <b>{t("request")}:</b>
                <li>
                  {t("id")}: {selectedRequest.id}
                </li>
                <li>
                  {t("address")}: {selectedRequest.address}
                </li>
                <li>
                  {t("latitude")}: <b>{t("decimal")}</b> {round(selectedRequest.lat, 6)} <b>{t("dms")}</b>{" "}
                  {latDecToDMS(selectedRequest.lat)}
                </li>
                <li>
                  {t("longitude")}: <b>{t("decimal")}</b> {round(selectedRequest.lng, 6)} <b>{t("dms")}</b>{" "}
                  {lngDecToDMS(selectedRequest.lng)}
                </li>
                <li>
                  {t("radius_no_unit")}: {selectedRequest.radius}m
                </li>
                <li>
                  {t("height_no_unit")}: {selectedRequest.height}m ({Math.ceil(selectedRequest.height * meterToFeetConversionRate)}
                  {t("feet")}) {t("above_ground")}
                </li>
                <li>
                  {t("planned")}: {convertToLocal(selectedRequest.plannedDate)}
                </li>
                {selectedRequest.activatedDate && (
                  <li>
                    {t("flight_start")}: {convertToLocal(selectedRequest.activatedDate)}
                  </li>
                )}
                {selectedRequest.endedDate && (
                  <li>
                    {t("flight_end")}: {convertToLocal(selectedRequest.endedDate)}
                  </li>
                )}
                {selectedRequest.flightTime > 0 && (
                  <li>
                    {t("flight_time")}: {selectedRequest.flightTime} {t("minutes")}
                  </li>
                )}
                {selectedRequest.estimatedFlightTime > 0 && (
                  <li>
                    {t("approximate_flight_time")}: {selectedRequest.estimatedFlightTime} {t("minutes")}
                  </li>
                )}
              </div>
            </ul>
            {selectedRequest?.ats?.betaFeatures && (
              <>
                <div>
                  <br />
                  <Paper sx={{ padding: 0.4 }} elevation={5}>
                    <div style={{ color: "#D18700" }}>BETA</div>
                    <ul>
                      <li>
                        {t("terrain")}: {elevation} {t("masl")} ({Math.ceil(elevation * meterToFeetConversionRate)}
                        {t("feet")} {t("qnh")})
                      </li>
                      <li>
                        <b>
                          {t("terrain")} + {t("flight_altitude")}: {elevation + selectedRequest.height}m (
                          {Math.ceil(requestHeightSeaLevel * meterToFeetConversionRate)}
                          {t("feet")} {t("qnh")})
                        </b>
                      </li>
                    </ul>
                  </Paper>
                </div>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
