import React from "react";
import acr from "../../assets/acr.webp";
import naviation from "../../assets/nav2.webp";
import "./Footer.css";
import { APP_VERSION } from "../../constants/constants";

export const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div>
          <a style={{ display: "flex", justifyContent: "flex-end" }} href="https://acr-sweden.se/">
            <img className="footer-image" src={acr} alt="ACR" />
          </a>
        </div>
        <div>
          <div className="footer-text">
            <p>Dronerequest {APP_VERSION}</p>
            <p>Operated by ACR - it@acr-sweden.se</p>
            <p>Alla tider visas för tidszon {Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
          </div>
        </div>
        <div className="footer-attribution">
          <p>Made by</p>
          <a id="footer-link" href="https://naviation.se">
            <img className="footer-image" src={naviation} alt="Naviation" />
          </a>
        </div>
      </div>
    </footer>
  );
};
