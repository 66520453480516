import { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { commentsApi } from "../../../api/api";
import { convertToLocal } from "../../../common/utils/functions";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import { DialogTitle, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { standardRequestsApi, requestsApi, settingsApi } from "../../../api/api";

export const CommentModal = ({
  show,
  handleClose,
  selectedUser,
  user,
  isLoading,
  setActive,
  setUpcoming,
  setEarlier,
  setErased,
  setTotalPagesActive,
  setTotalPagesUpcoming,
  setIsLoading,
  setTotalPagesEarlier,
  setTotalPagesErased,
  pageSize,
}) => {
  const { t } = useTranslation();
  const [modalToggle, setModalToggle] = useState(true);

  useEffect(() => {
    setModalToggle(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: (fields) => {
      if (fields.comment) {
        setIsLoading(true);
        setModalToggle(true);
        commentsApi
          .postComment(user, fields.comment, selectedUser.email)
          .then((res) => {
            Promise.all([
              requestsApi.getActiveByAts(1, pageSize, user.ats.id),
              requestsApi.getUpcomingByAts(1, pageSize, user.ats.id),
              requestsApi.getEarlierByAts(1, pageSize, user.ats.id),
              requestsApi.getErasedByAts(1, pageSize, user.ats.id),
            ])
              .then(([act, upc, ear, era]) => {
                const paginationActive = JSON.parse(act.headers.pagination);
                const paginationUpcoming = JSON.parse(upc.headers.pagination);
                const paginationEarlier = JSON.parse(ear.headers.pagination);
                const paginationErased = JSON.parse(era.headers.pagination);

                setTotalPagesActive(paginationActive.TotalPages);
                setTotalPagesUpcoming(paginationUpcoming.TotalPages);
                setTotalPagesEarlier(paginationEarlier.TotalPages);
                setTotalPagesErased(paginationErased.TotalPages);

                setActive(act.data);
                setUpcoming(upc.data);
                setEarlier(ear.data);
                setErased(era.data);

                formik.resetForm();
                setIsLoading(false);
                handleClose();
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
            setModalToggle(true);
          })
          .catch((err) => {
            console.log(err);
            formik.resetForm();
          });
      }
    },
  });

  if (!selectedUser) {
    return <div></div>;
  }

  if (!selectedUser.comments) {
    return <div></div>;
  }

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t("comments")}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {modalToggle ? (
            <>
              {!selectedUser.comments.length ? (
                <div>{t("comments_empty")}</div>
              ) : (
                <>
                  {selectedUser.comments.map((comment) => {
                    return (
                      <ul key={comment.id} className="comment-list">
                        <li className="comment-item">
                          <b>
                            {comment.author} - {convertToLocal(comment.date)}
                          </b>
                          <div className="mb-2">{comment.body}</div>
                        </li>
                      </ul>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  name="comment"
                  id="comment"
                  placeholder={t("write_comment")}
                  variant="outlined"
                  size="small"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {modalToggle ? (
            <>
              <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
                {t("close")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  setModalToggle(false);
                }}
              >
                {t("add_comment")}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="error"
                disabled={isLoading}
                onClick={() => {
                  setModalToggle(true);
                }}
              >
                {t("cancel")}
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={isLoading}>
                {t("save_changes")}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
