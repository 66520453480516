import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";

export const BackButtonWithTitle = ({ title }) => {
  let history = useHistory();
  const fontSize = window.innerWidth > 450 ? "30px" : "24px";

  return (
    <div className="page-title" style={{ marginBottom: 20 }}>
      <IconButton sx={{ padding: 0, marginRight: 2 }} onClick={() => history.goBack()} title="Gå bakåt">
        <ArrowBackIcon sx={{ color: "#303030", fontSize: { fontSize } }} />
      </IconButton>
      {title}
    </div>
  );
};
