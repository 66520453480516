import { MapContainer, TileLayer, Circle, Polygon, Marker, Popup } from "react-leaflet";
import { greenIcon, blueIcon, purpleIcon, lightGreenIcon, greyIcon } from "../../../common/utils/markers";

export const AtsMap = ({
  active,
  user,
  zones,
  previewZone,
  toggleZones,
  highlightActive,
  highlightUpcoming,
  highlightEarlier,
  highlightErased,
  restrictedZones,
}) => {
  return (
    <MapContainer id="map" center={[user.ats.lat, user.ats.lng]} zoom={9} scrollWheelZoom={true}>
      <TileLayer
        zIndex={-1}
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      {active?.map((request) => {
        return (
          <Circle
            key={request.id}
            center={[request.lat, request.lng]}
            radius={request.radius}
            fillColor="#00008b"
            fillOpacity={0.1}
            color="#00008b"
            opacity={0.2}
          >
            <Marker icon={greenIcon} position={[request.lat, request.lng]}>
              <Popup>
                <b>
                  Id: {request.id} <br /> Pilot: {request.appUser.displayName}
                </b>
              </Popup>
            </Marker>
          </Circle>
        );
      })}
      {highlightActive && (
        <Marker icon={lightGreenIcon} position={[highlightActive.lat, highlightActive.lng]}>
          {" "}
          <Popup>
            <b>
              Id: {highlightActive.id} <br /> Pilot: {highlightActive.appUser.displayName}
            </b>
          </Popup>
        </Marker>
      )}
      {highlightUpcoming && (
        <Circle
          center={[highlightUpcoming.lat, highlightUpcoming.lng]}
          radius={highlightUpcoming.radius}
          fillColor="#00008b"
          fillOpacity={0.1}
          color="#00008b"
          opacity={0.2}
        >
          <Marker icon={blueIcon} position={[highlightUpcoming.lat, highlightUpcoming.lng]}>
            <Popup>
              <b>
                Id: {highlightUpcoming.id} <br /> Pilot: {highlightUpcoming.appUser.displayName}
              </b>
            </Popup>
          </Marker>
        </Circle>
      )}
      {highlightEarlier && (
        <Circle
          center={[highlightEarlier.lat, highlightEarlier.lng]}
          radius={highlightEarlier.radius}
          fillColor="#00008b"
          fillOpacity={0.1}
          color="#00008b"
          opacity={0.2}
        >
          <Marker icon={purpleIcon} position={[highlightEarlier.lat, highlightEarlier.lng]}>
            <Popup>
              <b>
                Id: {highlightEarlier.id} <br /> Pilot: {highlightEarlier.appUser.displayName}
              </b>
            </Popup>
          </Marker>
        </Circle>
      )}
      {highlightErased && (
        <Circle
          center={[highlightErased.lat, highlightErased.lng]}
          radius={highlightErased.radius}
          fillColor="#00008b"
          fillOpacity={0.1}
          color="#00008b"
          opacity={0.2}
        >
          <Marker icon={greyIcon} position={[highlightErased.lat, highlightErased.lng]}>
            <Popup>
              <b>
                Id: {highlightErased.id} <br /> Pilot: {highlightErased.appUser.displayName}
              </b>
            </Popup>
          </Marker>
        </Circle>
      )}
      {toggleZones && (
        <div>
          {restrictedZones?.map((zone) => {
            if (zone.area !== null)
              return <Polygon key={zone.id} pathOptions={{ color: "red" }} positions={zone.area} opacity={0} fillOpacity={0.2} />;
            else
              return (
                <Circle
                  key={zone.id}
                  center={[zone.lat, zone.lng]}
                  radius={zone.radius}
                  fillColor="#FF0000"
                  color="#FF0000"
                  opacity={0}
                  fillOpacity={0.2}
                />
              );
          })}
        </div>
      )}
      {zones && (
        <div>
          {zones.map((zone) => {
            return <Polygon key={zone.id} pathOptions={{ color: "#008000" }} positions={zone.area} />;
          })}
        </div>
      )}
      {previewZone && <Polygon pathOptions={{ color: "#808080" }} positions={previewZone} />}

      <Polygon pathOptions={{ color: "red" }} positions={user.ats.airspace} fillOpacity={0} />
    </MapContainer>
  );
};
