import L from "leaflet";
import green from "../../assets/green.svg";
import lightGreen from "../../assets/lightgreen.svg";
import blue from "../../assets/blue.svg";
import red from "../../assets/red.svg";
import purple from "../../assets/purple.svg";
import grey from "../../assets/grey.svg";

export const greenIcon = L.icon({
  iconUrl: green,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const lightGreenIcon = L.icon({
  iconUrl: lightGreen,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const blueIcon = L.icon({
  iconUrl: blue,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const redIcon = L.icon({
  iconUrl: red,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const purpleIcon = L.icon({
  iconUrl: purple,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const greyIcon = L.icon({
  iconUrl: grey,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});
