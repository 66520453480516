import React, { useState, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const AccountContext = createContext();

export const AccountContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (!user)
      try {
        const jwt = localStorage.getItem("jwt");
        setUser(JSON.parse(jwt));
      } catch (err) {
        setTimeout(() => {
          history.push("/");
        }, 300);
      }
  }, [user]);

  // const onLogin = (fields, rememberMe) => {
  //   accountApi.login(fields);
  //   // .then((u) => {
  //   //   setUser(u.data);
  //   //   localStorage.setItem("jwt", JSON.stringify(u.data));
  //   //   if (rememberMe) {
  //   //     const date = new Date();
  //   //     date.setMonth(date.getMonth() + 6);
  //   //     document.cookie = `USER=${fields.email}; expires=${date.toUTCString()}; path=/`;
  //   //   }
  //   //   // if (!rememberMe) {
  //   //   //   if (checkRememberMeCookie(fields.email)) {
  //   //   //     document.cookie = `USER=${fields.email}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  //   //   //   }
  //   //   // }
  //   //   setIsLoading(false);
  //   //   if (u.data.changePassword) history.push("/change-password");
  //   //   else if (u.data.roles.includes("Ats")) history.push("/ats");
  //   //   else if (u.data.roles.includes("Admin")) history.push("/admin");
  //   //   else history.push("/pilot");
  //   // })
  //   // .catch((err) => {
  //   //   try {
  //   //     console.log(err.response.data);
  //   //     if (err) {
  //   //       handleOpenSnackbar("error", err.response.data);
  //   //       setError(err.response.data);
  //   //     } else {
  //   //       handleOpenSnackbar("error", "Något gick fel - försök igen om en liten stund");
  //   //       setError(err.toString());
  //   //     }
  //   //     setIsLoading(false);
  //   //   } catch (err) {
  //   //     console.log(err);
  //   //   }
  //   // });
  // };

  // const onRegister = (fields) => {
  //   setIsLoading(true);
  //   accountApi
  //     .register(fields)
  //     .then((u) => {
  //       setIsLoading(false);
  //       history.push(`/register-success?email=${fields.email}`);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //       setError(err.toString());
  //     });
  // };

  // const onLogout = () => {
  //   localStorage.clear();
  //   setUser(null);
  // };

  // const onChangePhoneNumber = (newPhoneNumber) => {
  //   try {
  //     const updatedUser = { ...user, phoneNumber: newPhoneNumber };
  //     localStorage.clear();
  //     localStorage.setItem("jwt", JSON.stringify(updatedUser));
  //     setUser(updatedUser);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const onChangeATS = (ats) => {
  //   try {
  //     const updatedUser = { ...user, ats: ats };
  //     localStorage.clear();
  //     localStorage.setItem("jwt", JSON.stringify(updatedUser));
  //     setUser(updatedUser);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <AccountContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
