import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import { BackButton } from "../../common/components/BackButton";
import { restrictedZonesApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { PreviewModal } from "../../common/components/PreviewModal";
import { FormButton } from "../../common/utils/stylings";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import Paper from "@mui/material/Paper";

const EditRestrictedZone = ({ selected, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    else if (!selected) history.goBack();
    scrollToTop();
  }, []);

  const onPutRestrictedZone = (fields, selected, area) => {
    setIsLoading(true);
    if (!area) {
      restrictedZonesApi
        .putRestrictedZone(fields, selected)
        .then((res) => {
          handleOpenSnackbar("success", t("changes_saved"));
          setIsLoading(false);
          history.goBack();
        })
        .catch((err) => {
          handleOpenSnackbar("error", t("generic_error"));
          setIsLoading(false);
          setError(err);
        });
    } else {
      restrictedZonesApi
        .putRestrictedZone(fields, selected, area)
        .then((res) => {
          handleOpenSnackbar("success", t("changes_saved"));
          setIsLoading(false);
          history.goBack();
        })
        .catch((err) => {
          handleOpenSnackbar("error", t("generic_error"));
          setIsLoading(false);
          setError(err);
        });
    }
  };

  if (error) {
    return <div>{error.toString()}</div>;
  }

  return (
    <div className="narrow-container">
      <Paper sx={{ padding: 2 }} elevation={3}>
        <BackButtonWithTitle title={t("edit_restricted_area")} />
        <div className="item">
          {isLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          )}
          {!selected.area ? (
            <EditRestrictedZonePoint
              selected={selected}
              onPutRestrictedZone={onPutRestrictedZone}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          ) : (
            <EditRestrictedZonesPolygon
              selected={selected}
              onPutRestrictedZone={onPutRestrictedZone}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleOpenSnackbar={handleOpenSnackbar}
            />
          )}
        </div>
      </Paper>
    </div>
  );
};

export default EditRestrictedZone;

const EditRestrictedZonePoint = ({ selected, onPutRestrictedZone, isLoading, setIsLoading }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name_required")),
    latitude: Yup.string()
      .matches(/^[1-9]\d*(.\d+)?$/, t("only_letters_and_whitespace"))
      .required(t("latitude_required")),
    longitude: Yup.string()
      .matches(/^[1-9]\d*(.\d+)?$/, t("only_letters_and_whitespace"))
      .required(t("longitude_required")),
    radius: Yup.string()
      .matches(/^[0-9]*$/, t("only_numbers"))
      .required(t("radius_required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      latitude: "",
      longitude: "",
      radius: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onPutRestrictedZone(fields, selected);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    try {
      formik.setFieldValue("name", selected.name);
      formik.setFieldValue("latitude", selected.lat);
      formik.setFieldValue("longitude", selected.lng);
      formik.setFieldValue("radius", selected.radius);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  if (isLoading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <TextField
          variant="standard"
          fullWidth
          id="name"
          name="name"
          label="Namn"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            variant="standard"
            fullWidth
            type="number"
            style={{ width: "49%" }}
            id="latitude"
            name="latitude"
            label="Latitud"
            value={formik.values.latitude}
            onChange={formik.handleChange}
            error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            helperText={formik.touched.latitude && formik.errors.latitude}
          />
          <TextField
            variant="standard"
            fullWidth
            type="number"
            style={{ width: "49%" }}
            id="longitude"
            name="longitude"
            label="Longitud"
            value={formik.values.longitude}
            onChange={formik.handleChange}
            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            helperText={formik.touched.longitude && formik.errors.longitude}
          />
        </div>
        <TextField
          variant="standard"
          fullWidth
          type="number"
          id="radius"
          name="radius"
          label="Radie"
          value={formik.values.radius}
          onChange={formik.handleChange}
          error={formik.touched.radius && Boolean(formik.errors.radius)}
          helperText={formik.touched.radius && formik.errors.radius}
        />
      </div>
      <Button sx={{ marginBottom: 1, marginTop: 1 }} type="submit" variant={"contained"}>
        {t("save_changes")}
      </Button>
    </form>
  );
};

const EditRestrictedZonesPolygon = ({ selected, onPutRestrictedZone, isLoading, setIsLoading, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name_required")),
    area: Yup.string().required(t("area_required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      area: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      console.log(fields);
      onPutRestrictedZone(fields, selected, JSON.parse(fields.area));
    },
  });

  useEffect(() => {
    setIsLoading(true);
    try {
      formik.setFieldValue("name", selected.name);
      formik.setFieldValue("area", JSON.stringify(selected.area));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  if (isLoading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );

  return (
    <form onSubmit={formik.handleSubmit}>
      <PreviewModal show={show} handleClose={handleClose} preview={preview} />
      <div>
        <TextField
          variant="standard"
          fullWidth
          id="name"
          name="name"
          label="Namn"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          variant="standard"
          multiline
          rows={2}
          fullWidth
          id="area"
          area="area"
          label="Område"
          value={formik.values.area}
          onChange={formik.handleChange}
          error={formik.touched.area && Boolean(formik.errors.area)}
          helperText={formik.touched.area && formik.errors.area}
        />
        <Alert sx={{ marginBottom: 1, marginTop: 1, fontSize: 11 }} severity="info">
          {t("polygon_info")}: [[{t("lat")}, {t("lng")}],[{t("lat")}, {t("lng")}]]. {t("example")}:
          [[57.10767047261603,12.246970735156431],[57.257677016095315,12.195017752105384],[56.945690754327906,12.741481446690694]]{" "}
        </Alert>
        <Button
          type="button"
          sx={{ textTransform: "none" }}
          onClick={() => {
            try {
              const parseArea = JSON.parse(formik.values.area);
              setPreview(parseArea);
              handleShow();
            } catch (err) {
              handleOpenSnackbar("error", t("area_invalid"));
              console.log(t("area_invalid"), err);
            }
          }}
        >
          {t("preview_in_map")}
        </Button>
      </div>
      <FormButton type="submit" variant={"contained"}>
        {t("save_changes")}
      </FormButton>
    </form>
  );
};
