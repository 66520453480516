import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../common/components/BackButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "../../common/hooks/hooks";
import { atsApi } from "../../api/api";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import { FormButton } from "../../common/utils/stylings";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const Airspace = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const id = useQuery().get("id");
  const [airspace, setAirspace] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [name, setName] = useState();
  const [preview, setPreview] = useState();

  let history = useHistory();

  const validationSchema = Yup.object().shape({
    airspace: Yup.string()
      .required(t("airspace_required"))
      .matches(/^[0-9,\.\[\]]*$/, t("only_numbers_and_these_symbols"))
      .test("format", t("invalid_format"), (value) => {
        try {
          const parsed = JSON.parse(value);
          if (parsed.length === 0) return false;
          parsed.map((e) => {
            if (!Array.isArray(e)) {
              return false;
            }
          });
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      }),
  });

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    const retrieveData = () => {
      setIsPageLoading(true);
      atsApi
        .getAts(id)
        .then((res) => {
          setName(res.data.name);
          setAirspace(res.data.airspace);
          formik.setFieldValue("airspace", JSON.stringify(res.data.airspace));
          setLat(res.data.lat);
          setLng(res.data.lng);
          setIsPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsPageLoading(false);
        });
    };
    retrieveData();
  }, []);

  const onPatchAts = (id, path, op, value) => {
    atsApi
      .patchAts(id, path, op, value)
      .then((res) => {
        handleOpenSnackbar("success", t("changes_saved"));
        history.goBack();
      })
      .catch((err) => {
        handleOpenSnackbar("error", t("generic_error"));
      });
  };

  const formik = useFormik({
    autocomplete: "off",
    enableReinitialize: false,
    initialValues: {
      airspace: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onPatchAts(id, "/airspace", "replace", JSON.parse(fields.airspace));
    },
  });

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="standard-container">
          <BackButtonWithTitle title={t("airspace_name", { name: name })} />
          <div className="item">
            <div className="map-container">{airspace && <AirspaceMap airspace={airspace} lat={lat} lng={lng} preview={preview} />}</div>
            <div style={{ marginTop: 10 }}>
              <TextField
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                id="airspace"
                airspace="airspace"
                label={t("airspace")}
                value={formik.values.airspace}
                onChange={formik.handleChange}
                error={formik.touched.airspace && Boolean(formik.errors.airspace)}
                helperText={formik.touched.airspace && formik.errors.airspace}
              />
              <Alert sx={{ marginBottom: 1, marginTop: 1, fontSize: 11 }} severity="info">
                {t("polygon_info")}: [[{t("lat")}, {t("lng")}],[{t("lat")}, {t("lng")}]]. {t("example")}:
                [[57.10767047261603,12.246970735156431],[57.257677016095315,12.195017752105384],[56.945690754327906,12.741481446690694]]{" "}
              </Alert>
              <Button
                type="button"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  try {
                    const parseAirspace = JSON.parse(formik.values.airspace);
                    setPreview(parseAirspace);
                  } catch (err) {
                    handleOpenSnackbar("error", t("area_invalid"));
                    console.log(t("area_invalid"), err);
                  }
                }}
              >
                {t("preview_in_map")}
              </Button>
            </div>
            <FormButton type="submit" variant={"contained"}>
              {t("save_changes")}
            </FormButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Airspace;

const AirspaceMap = ({ airspace, lat, lng, preview }) => {
  return (
    <MapContainer id="map" center={[lat, lng]} zoom={9.2}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      <Polygon pathOptions={{ color: "red" }} positions={airspace} fillOpacity={0.1} />
      {preview && <Polygon pathOptions={{ color: "green" }} positions={preview} fillOpacity={0.5} />}
    </MapContainer>
  );
};
