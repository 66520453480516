import TextField from "@mui/material/TextField";
import { accountApi } from "../../../api/api";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

export const AtsAccountsToolbar = ({ setAtsAccounts, search, setSearch, setIsLoading }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (fields) => {
      setIsLoading(true);
      setSearch(fields.search);
      accountApi
        .searchUsersByRoleWithAts(1, 20, "Ats", fields.search)
        .then((res) => {
          setAtsAccounts(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
  });

  return (
    <form className="table-toolbar" onSubmit={formik.handleSubmit}>
      <div>
        {search && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            {t("search_results")} "{search}"{" "}
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => {
                accountApi
                  .getUsersByRoleWithAts(1, 20, "Ats")
                  .then((res) => {
                    setAtsAccounts(res.data);
                    setSearch("");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
        )}
      </div>
      <TextField
        sx={{ width: window.innerWidth > 450 ? "40%" : "100%", marginBottom: 1, marginTop: 1 }}
        variant="outlined"
        size="small"
        id="search"
        name="search"
        value={formik.values.search}
        onChange={formik.handleChange}
        placeholder={t("search")}
        InputProps={{
          type: "search",
          startAdornment: <SearchIcon fontSize="medium" />,
        }}
      />
    </form>
  );
};
