import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import { requestsApi } from "../../../api/api";
import { AccountContext } from "../../../contexts/AccountContext";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";

export const ErasedToolbar = ({
  setErased,
  search,
  setSearch,
  setIsLoading,
  setHighlightErased,
  setCurrPageErased,
  setTotalPagesErased,
  pageSize,
}) => {
  const { user } = useContext(AccountContext);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (fields) => {
      setIsLoading(true);
      setSearch(fields.search);
      requestsApi
        .searchErasedByAts(1, pageSize, user.ats.id, fields.search)
        .then((res) => {
          const pagination = JSON.parse(res.headers.pagination);
          setTotalPagesErased(pagination.TotalPages);
          setCurrPageErased(1);
          setHighlightErased();
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
  });

  const refresh = () => {
    setIsLoading(true);
    requestsApi
      .getErasedByAts(1, pageSize, user.ats.id)
      .then((res) => {
        const pagination = JSON.parse(res.headers.pagination);
        setTotalPagesErased(pagination.TotalPages);
        setErased(res.data);
        setCurrPageErased(1);
        setHighlightErased();
        setSearch("");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <form className="table-toolbar" onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Tooltip title={t("refresh")}>
          <IconButton size={window.innerWidth > 1000 ? "large" : "medium"} onClick={refresh} aria-label="refresh">
            <RefreshIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Tooltip>{" "}
        {search && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            {t("search_results")} "{search}"{" "}
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => {
                requestsApi
                  .getErasedByAts(1, pageSize, user.ats.id)
                  .then((res) => {
                    const pagination = JSON.parse(res.headers.pagination);
                    setTotalPagesErased(pagination.TotalPages);
                    setCurrPageErased(1);
                    setErased(res.data);
                    setSearch("");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
        )}
      </div>
      <TextField
        sx={{ width: window.innerWidth > 450 ? "40%" : "100%", marginBottom: 1, marginTop: 1 }}
        variant="outlined"
        size="small"
        id="search"
        name="search"
        value={formik.values.search}
        onChange={formik.handleChange}
        placeholder={t("search")}
        InputProps={{
          type: "search",
          startAdornment: <SearchIcon fontSize="medium" />,
        }}
      />
    </form>
  );
};
