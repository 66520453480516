export const getLang = () => {
  const c = document.cookie.split("; ");
  const language = c.filter((item) => {
    return item.includes("LANG");
  });
  if (language.length > 0) {
    const lang = language[0].split("=")[1];
    return lang;
  } else {
    return "sv-SE";
  }
};

export const checkConsentCookie = () => {
  const c = document.cookie.split("; ");
  if (c.includes("CONSENT=true")) {
    return true;
  } else {
    return false;
  }
};

export const checkRememberMeCookie = (email) => {
  const c = document.cookie.split("; ");
  if (c.includes(`USER=${email}`)) {
    return true;
  } else {
    return false;
  }
};

export const doesRememberMeCookieExist = () => {
  const c = document.cookie.split("; ");
  const cookie = c.filter((item) => {
    return item.includes("USER");
  });
  if (cookie.length > 0) {
    return true;
  } else {
    return false;
  }
};

// export const dateISOLocalTime = (d) => {
//   if (d !== undefined) {
//     const date = d.toLocaleDateString();
//     const time = d.toLocaleTimeString();
//     return date + "T" + time;
//   } else {
//     const nd = new Date();
//     const date = nd.toLocaleDateString();
//     const time = nd.toLocaleTimeString();
//     return date + "T" + time;
//   }
// };

export const convertToUnixTimeStamp = (d) => {
  if (d) return Math.floor(d.getTime() / 1000);
  else return Math.floor(new Date().getTime() / 1000);
};

export const convertToLocal = (u) => {
  const date = new Date(u * 1000);
  return date.toLocaleString(getLang());
};

// export const formatDate = (isoDate) => {
//   const s = isoDate.split("T");
//   const date = s[0];
//   const timeSlice = s[1].slice(0, -1).split(".");
//   const timeSplit = timeSlice[0].split(":");
//   const time = timeSplit[0] + ":" + timeSplit[1];
//   return date + " " + time;
// };

export const partition = (array, filter) => {
  let pass = [],
    fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
};

// export const convertDMS = (lat, lng) => {
//   var latitude = toDegreesMinutesAndSeconds(lat);
//   var latitudeCardinal = lat >= 0 ? "N" : "S";

//   var longitude = toDegreesMinutesAndSeconds(lng);
//   var longitudeCardinal = lng >= 0 ? "E" : "W";

//   return latitude + " " + latitudeCardinal + "\n" + longitude + " " + longitudeCardinal;
// };

export const toDegreesMinutesAndSeconds = (coordinate) => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + "° " + minutes + "' " + seconds + '" ';
};

export const latDecToDMS = (lat) => {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";
  return latitude + " " + latitudeCardinal;
};

export const lngDecToDMS = (lng) => {
  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";
  return longitude + " " + longitudeCardinal;
};

export const timeDiff = (activatedDate) => {
  const activated = new Date(activatedDate * 1000);
  const current = new Date();
  return Math.floor((current - activated) / 60e3);
};

export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 100);
};

export const round = (number, decimalPlaces) => Number(Math.round(Number(number + "e" + decimalPlaces)) + "e" + decimalPlaces * -1);
