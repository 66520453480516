import axios from "axios";
import { convertToUnixTimeStamp, getLang } from "../common/utils/functions";

export const accountApi = {
  login: async (user) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/login`, {
      email: user.email,
      password: user.password,
    });
  },
  register: async (user) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/register`, {
      email: user.email,
      displayName: user.name,
      phoneNumber: user.phoneNumber,
      password: user.password,
    });
  },
  verifyEmail: async (token, email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/verify-email?email=${email}&token=${token}`);
  },
  resendEmailConfirm: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/resend-email-confirm?email=${email}`);
  },
  // resetPassword: async (token, email, password) => {
  //   return await axios.post(`${lang}/account/reset-password?token=${token}&email=${email}&password=${password}`);
  // },
  resetPassword: async (token, email, password) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/reset-password`, {
      email: email,
      password: password,
      token: token,
    });
  },
  resetPasswordRequest: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/reset-password-confirmation-link?email=${email}`);
  },
  // changePassword: async (email, currentPassword, newPassword) => {
  //   return await axios.post(`${lang}/account/change-password?email=${email}&currentPassword=${currentPassword}&newPassword=${newPassword}`, {
  //     email: email,
  //     currentPassword: currentPassword,
  //     newPassword: newPassword,
  //   });
  // },
  changePassword: async (email, currentPassword, newPassword) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-password`, {
      email: email,
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  },
  // changePhoneNumber: async (email, newPhoneNumber) => {
  //   return await axios.post(`${lang}/account/change-phone-number?email=${email}&newPhoneNumber=${newPhoneNumber}`);
  // },
  changePhoneNumber: async (email, newPhoneNumber) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-phone-number`, {
      email: email,
      phoneNumber: newPhoneNumber,
    });
  },
  changeEmailConfirmationLink: async (currentEmail, newEmail) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-email-confirmation-link?currentEmail=${currentEmail}&newEmail=${newEmail}`);
  },
  // changeEmail: async (token, email, newEmail) => {
  //   const lang = getLang();
  //   return await axios.post(`${lang}/account/change-email?token=${token}&email=${email}&newEmail=${newEmail}`);
  // },
  changeEmail: async (token, email, newEmail) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/change-email`, {
      email: email,
      newEmail: newEmail,
      token: token,
    });
  },
  addUserToATS: async (user, atsId) => {
    const lang = getLang();
    return await axios.put(`${lang}/account?email=${user.email}&id=${user.id}&atsId=${atsId}`);
  },
  createAtsAccount: async (fields) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/register-ats-user`, {
      email: fields.email,
      displayName: fields.accountName,
      password: fields.password,
      phoneNumber: fields.phoneNumber,
    });
  },
  createAdminAccount: async (fields) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/register-admin-user`, {
      email: fields.email,
      displayName: fields.name,
      password: fields.password,
      phoneNumber: fields.phoneNumber,
    });
  },
  // getUser: async (email, id) => {
  //   return await axios.get(`${lang}/account/user?email=${email}&id=${id}`);
  // },
  getUsers: async (page = 1) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/users?pageNumber=${page}&pageSize=20`);
  },
  getUsersByRole: async (pageNumber = 1, pageSize = 20, role) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/users-by-role?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}`);
  },
  searchUsersByRole: async (pageNumber = 1, pageSize = 20, role, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/account/search/users-by-role?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}&search=${search}`
    );
  },
  getUsersByRoleWithAts: async (pageNumber = 1, pageSize = 20, role) => {
    const lang = getLang();
    return await axios.get(`${lang}/account/users-by-role-with-ats?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}`);
  },
  searchUsersByRoleWithAts: async (pageNumber = 1, pageSize = 20, role, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/account/search/users-by-role-with-ats?pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}&search=${search}`
    );
  },
  deleteUser: async (email, password) => {
    const lang = getLang();
    return await axios.delete(`${lang}/account/delete?email=${email}&password=${password}`);
  },
  banUser: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/ban-user?email=${email}`);
  },
  unBanUser: async (email) => {
    const lang = getLang();
    return await axios.post(`${lang}/account/un-ban-user?email=${email}`);
  },
  patchUser: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/account/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
};

export const requestsApi = {
  getUpcomingByUser: async (pageNumber = 1, pageSize = 5, email) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/upcoming/user?email=${email}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getEarlierByUser: async (pageNumber = 1, pageSize = 5, email) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/earlier/user?email=${email}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getErasedByUser: async (pageNumber = 1, pageSize = 5, email) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/erased/user?email=${email}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getActiveByAts: async (pageNumber = 1, pageSize = 5, atsId) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/active/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  getUpcomingByAts: async (pageNumber = 1, pageSize = 5, atsId) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/upcoming/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  getEarlierByAts: async (pageNumber = 1, pageSize = 5, atsId) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/earlier/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  getErasedByAts: async (pageNumber = 1, pageSize = 5, atsId) => {
    const lang = getLang();
    return await axios.get(`${lang}/requests/erased/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  searchUpcomingByUser: async (pageNumber, pageSize, email, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/upcoming/user?email=${email}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  searchEarlierByUser: async (pageNumber, pageSize, email, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/earlier/user?email=${email}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  searchErasedByUser: async (pageNumber, pageSize, email, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/erased/user?email=${email}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  searchActiveByAts: async (pageNumber, pageSize, atsId, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/active/ats?atsId=${atsId}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  searchUpcomingByAts: async (pageNumber, pageSize, atsId, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/upcoming/ats?atsId=${atsId}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  searchEarlierByAts: async (pageNumber, pageSize, atsId, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/earlier/ats?atsId=${atsId}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  searchErasedByAts: async (pageNumber, pageSize, atsId, search) => {
    const lang = getLang();
    return await axios.get(
      `${lang}/requests/search/erased/ats?atsId=${atsId}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  // postRequest: async (request, lat, lng, plannedDate, ats) => {
  //   const lang = getLang();
  //   return await axios.post(`${lang}/requests`, {
  //     height: request.height,
  //     radius: request.radius,
  //     lat: lat,
  //     lng: lng,
  //     address: request.address,
  //     estimatedFlightTime: request.estimatedFlightTime,
  //     plannedDate: dateISOLocalTime(plannedDate),
  //     ats: ats,
  //   });
  // },
  postRequest: async (request) => {
    const lang = getLang();
    return await axios.post(
      `${lang}/requests`,
      {
        height: request.height,
        radius: request.radius,
        address: request.address,
        lat: request.latitude,
        lng: request.longitude,
        estimatedFlightTime: request.estimatedFlightTime,
        plannedDate: request.plannedDate,
        ats: request.ats,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
  },
  postCopiedRequest: async (request, plannedDate) => {
    const lang = getLang();
    const requestObj = {
      ...request,
      plannedDate: plannedDate,
    };
    return await axios.post(`${lang}/requests/alt?email=${request.appUser.email}`, requestObj);
  },
  patchRequest: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/requests/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
  eraseRequest: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/requests/erase/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
};

export const atsApi = {
  getAts: async (id) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/${id}`);
  },
  getAtses: async (pageNumber = 1, pageSize = 20) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  },
  getAtsesTest: async (page = 1) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/test?pageNumber=${page}&pageSize=20`);
  },
  getAtsesProduction: async (page = 1) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/production?pageNumber=${page}&pageSize=20`);
  },
  searchAtses: async (pageNumber = 1, pageSize = 20, search) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/search?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`);
  },
  postAts: async (fields, airspace, emailNotifications) => {
    const lang = getLang();
    return await axios.post(`${lang}/ats`, {
      name: fields.name,
      phoneNumber: fields.phoneNumber,
      lat: fields.latitude,
      lng: fields.longitude,
      airspace: airspace,
      disclaimer: fields.disclaimer,
      emailNotifications: emailNotifications,
      userEmail: fields.email,
      userPassword: fields.password,
      userName: fields.email,
      UserDisplayName: fields.accountName,
    });
  },
  patchAts: async (id, path, op, value) => {
    const lang = getLang();
    return await axios.patch(`${lang}/ats/${id}`, [
      {
        value: value,
        path: path,
        op: op,
      },
    ]);
  },
  toggleBetaFeatures: async (id) => {
    const lang = getLang();
    return await axios.get(`${lang}/ats/toggle?${id}`);
  },
};

export const commentsApi = {
  getCommentsByUser: async (email, page = 1, pageSize = 5) => {
    const lang = getLang();
    return await axios.get(`${lang}/comments?email=${email}&pageNumber=${page}&pageSize=${pageSize}`);
  },
  postComment: async (user, comment, email) => {
    const lang = getLang();
    return await axios.post(`${lang}/comments?email=${email}`, {
      author: user.email,
      body: comment,
      date: convertToUnixTimeStamp(),
    });
  },
};

export const restrictedZonesApi = {
  getRestrictedZones: async (page = 1, pageSize = 1000) => {
    const lang = getLang();
    return await axios.get(`${lang}/restrictedzones?pageNumber=${page}&pageSize=${pageSize}`);
  },
  getRestrictedByAts: async (atsId, page = 1, pageSize = 1000) => {
    const lang = getLang();
    return await axios.get(`${lang}/restrictedzones/ats?pageNumber=${page}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  postRestrictedZone: async (fields, ats, area) => {
    const lang = getLang();
    return await axios.post(`${lang}/restrictedzones`, {
      name: fields.name,
      area: area,
      lat: fields.latitude,
      lng: fields.longitude,
      radius: fields.radius,
      ats: ats,
    });
  },
  putRestrictedZone: async (fields, selected, area) => {
    const lang = getLang();
    return await axios.put(`${lang}/restrictedzones`, {
      id: selected.id,
      name: fields.name,
      area: area,
      lat: fields.latitude,
      lng: fields.longitude,
      radius: fields.radius,
      ats: selected.ats,
    });
  },
  deleteRestrictedZone: async (id) => {
    const lang = getLang();
    return await axios.delete(`${lang}/restrictedzones/${id}`);
  },
};

export const standardRequestsApi = {
  getStandardRequests: async (page = 1, pageSize = 10) => {
    const lang = getLang();
    return await axios.get(`${lang}/standardrequests?pageNumber=${page}&pageSize=${pageSize}`);
  },
  getStandardByAts: async (pageNumber = 1, pageSize = 10, atsId) => {
    const lang = getLang();
    return await axios.get(`${lang}/standardrequests/ats?pageNumber=${pageNumber}&pageSize=${pageSize}&atsId=${atsId}`);
  },
  postStandardRequest: async (fields, ats, area) => {
    const lang = getLang();
    return await axios.post(`${lang}/standardrequests`, {
      name: fields.name,
      area: area,
      lat: fields.latitude,
      lng: fields.longitude,
      ats: ats,
    });
  },
  putStandardRequest: async (fields, selected, area) => {
    const lang = getLang();
    return await axios.put(`${lang}/standardrequests`, {
      id: selected.id,
      name: fields.name,
      area: area,
      lat: fields.lat,
      lng: fields.lng,
      ats: selected.ats,
    });
  },
  deleteStandardRequest: async (id) => {
    const lang = getLang();
    return await axios.delete(`${lang}/standardrequests/${id}`);
  },
};

export const settingsApi = {
  getPageSize: async () => {
    const lang = getLang();
    return await axios.get(`${lang}/settings/page`);
  },
  updatePageSize: async (pageSize) => {
    const lang = getLang();
    return await axios.put(`${lang}/settings/page?pageSize=${pageSize}`);
  },
};

export const countriesApi = {
  getCountries: async () => {
    const lang = getLang();
    return await axios.get(`${lang}/countries`);
  },
};
