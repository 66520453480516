import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertToLocal, convertToUnixTimeStamp } from "../../../common/utils/functions";
import { requestsApi } from "../../../api/api";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AccountContext } from "../../../contexts/AccountContext";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import { useFormik } from "formik";

export const EditTimeModal = ({
  show,
  handleClose,
  setIsLoading,
  selectedRequest,
  handleOpenSnackbar,
  setEarlier,
  earlier,
  currPageEarlier,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);

  const [activatedDate, setActivatedDate] = useState();
  const [endedDate, setEndedDate] = useState();

  useEffect(() => {
    if (selectedRequest) {
      setActivatedDate(new Date(selectedRequest.activatedDate * 1000));
      setEndedDate(new Date(selectedRequest.endedDate * 1000));
    }
  }, [selectedRequest, earlier]);

  const formik = useFormik({
    initialValues: {
      error: null,
    },
    validateOnChange: true,
    onSubmit: (fields) => {
      const updatedActivatedDate = new Date(activatedDate);
      const updatedEndedDate = new Date(endedDate);
      const updatedFlightTime = Math.floor((updatedEndedDate - updatedActivatedDate) / 60e3);

      if (updatedFlightTime < 0) {
        handleOpenSnackbar("error", t("endtime_before_starttime"));
        setActivatedDate(selectedRequest.activatedDate * 1000);
        setEndedDate(selectedRequest.endedDate * 1000);
        return;
      }
      setIsLoading(true);
      Promise.all([
        requestsApi.patchRequest(selectedRequest.id, "/activatedDate", "replace", convertToUnixTimeStamp(updatedActivatedDate)),
        requestsApi.patchRequest(selectedRequest.id, "/endedDate", "replace", convertToUnixTimeStamp(updatedEndedDate)),
        requestsApi.patchRequest(selectedRequest.id, "/flightTime", "replace", updatedFlightTime),
      ]).then((res) => {
        requestsApi
          .getEarlierByAts(currPageEarlier, 5, user.ats.id)
          .then((res) => {
            setEarlier(res.data);
            setIsLoading(false);
            handleOpenSnackbar("success", t("changes_saved"));
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            handleClose();
          });
      });
    },
  });

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <div style={{ marginTop: 20, marginBottom: 20 }}></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                inputFormat="yyyy-MM-dd HH:mm"
                id="date"
                fullWidth
                name="date"
                DialogProps={{
                  PaperProps: {
                    sx: { "& *:focus": { outline: "none" } },
                  },
                }}
                label={t("start_datetime")}
                ampm={false}
                value={activatedDate}
                onChange={(date) => {
                  setActivatedDate(date);
                }}
                renderInput={(params) => <TextField style={{ width: "50%" }} fullWidth {...params} variant="outlined" size="small" />}
              />
              <MobileDateTimePicker
                inputFormat="yyyy-MM-dd HH:mm"
                id="date"
                fullWidth
                name="date"
                label={t("end_datetime")}
                DialogProps={{
                  PaperProps: {
                    sx: { "& *:focus": { outline: "none" } },
                  },
                }}
                ampm={false}
                value={endedDate}
                onChange={(date) => {
                  setEndedDate(date);
                }}
                renderInput={(params) => <TextField style={{ width: "50%" }} fullWidth {...params} variant="outlined" size="small" />}
              />
            </LocalizationProvider>
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}></div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button variant="contained" type="submit" color="primary">
            {t("save_changes")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
