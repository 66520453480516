import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { FormButton } from "../../common/utils/stylings";
import { accountApi, countriesApi } from "../../api/api";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { countryCodes } from "../../common/utils/countryCodes";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Paper from "@mui/material/Paper";

const ChangePhoneNumber = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const { user, setUser } = useContext(AccountContext);
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required(t("phone_nr_required")),
  });

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 50);
  }, []);

  const formik = useFormik({
    initialValues: {
      country: "",
      phoneNumber: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      accountApi
        .changePhoneNumber(user.email, fields.phoneNumber)
        .then(() => {
          try {
            const updatedUser = { ...user, phoneNumber: fields.phoneNumber };
            localStorage.clear();
            localStorage.setItem("jwt", JSON.stringify(updatedUser));
            setUser(updatedUser);
          } catch (err) {
            console.log(err);
          }

          // onChangePhoneNumber(fields.phoneNumber);
        })
        .then((res) => {
          handleOpenSnackbar("success", t("phone_nr_success"));
          history.goBack();
        })
        .catch((err) => {
          handleOpenSnackbar("error", t("phone_nr_failed"));
          console.log(t("phone_nr_failed"));
        });
    },
  });

  useEffect(() => {
    countriesApi
      .getCountries()
      .then((res) => {
        setCountries(res.data);
        let c = "";
        try {
          const cookie = document.cookie.split("; ");
          const language = cookie.filter((item) => {
            return item.includes("LANG");
          });
          if (language.length > 0) {
            const cookieLang = language[0].split("=")[1];
            const get639 = cookieLang.split("-")[0];
            c = res.data.find((c) => c.iso639 === get639);
          } else {
            const browserLang = navigator.language || navigator.userLanguage;
            c = res.data.find((c) => c.iso639 === browserLang);
          }
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        } catch (err) {
          c = res.data.find((c) => c.iso639 === "en");
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    formik.setFieldValue("country", event.target.value);
    if (!event.target.value) formik.setFieldValue("phoneNumber", "");
    else {
      const c = countries.find((c) => c.iso3166 === event.target.value);
      formik.setFieldValue("phoneNumber", c.phonePrefix);
    }
  };

  return (
    <div>
      <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <Paper sx={{ padding: 2 }} elevation={3}>
            <BackButtonWithTitle title={t("change_phone")} />
            <div className="item">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl sx={{ width: "70%", marginRight: 0 }} variant="standard">
                  <InputLabel shrink={true} id="ats-select">
                    {t("country_code")}
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.country}
                    onChange={handleChange}
                    inputProps={{
                      name: "ats",
                      id: "uncontrolled-native",
                    }}
                    id="ats-select"
                  >
                    {countries?.map((country) => {
                      return (
                        <option key={country.iso3166} value={country.iso3166}>
                          {t(`${country.iso3166}`)}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {/* <Autocomplete
                id="country-select"
                style={{ width: "70%", marginRight: 0 }}
                options={countries}
                value={formik.values.country}
                onChange={(event, newValue) => {
                  formik.setFieldValue("country", newValue);
                  // focusPhoneNumber()

                  if (!newValue) formik.setFieldValue("phoneNumber", "");
                  else formik.setFieldValue("phoneNumber", newValue.phonePrefix);
                }}
                getOptionLabel={(option) => {
                  return t(`${option.iso3166}`);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {t(`${option.iso3166}`)} ({option.phonePrefix})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    type="text"
                    variant="standard"
                    label={t("country_code")}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              /> */}
                <TextField
                  variant="standard"
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  label={t("phone_nr")}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </div>
              <FormButton type="submit" variant={"contained"}>
                {t("save")}
              </FormButton>
            </div>
          </Paper>
        </div>
      </form>
    </div>
  );
};

export default ChangePhoneNumber;
