import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormButton } from "../../common/utils/stylings";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="narrow-container">
      <div className="item">
        <div>
          <div className="page-title">{t("page_not_found")}</div>
          <p>{t("page_not_found2")}</p>
        </div>
        <FormButton
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/");
          }}
        >
          {t("go_to_home")}
        </FormButton>
      </div>
    </div>
  );
};

export default NotFound;
