import * as Yup from "yup";
import { BackButton } from "../../common/components/BackButton";
import { FormButton } from "../../common/utils/stylings";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import React, { useState, useEffect, useContext } from "react";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import Paper from "@mui/material/Paper";

const ChangePasswordLoggedIn = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();

  const { user, setUser } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().min(8, t("password_required_length")).required(t("current_password_required")),
    password: Yup.string().min(8, t("password_required_length")).required(t("new_password_required")),
    confirmPassword: Yup.string().min(8, t("password_required_length")).required(t("repeat_new_password_required")),
  });

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 50);
  }, []);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      if (fields.password !== fields.confirmPassword) {
        handleOpenSnackbar("error", t("password_match"));
        return;
      }

      accountApi
        .changePassword(user.email, fields.currentPassword, fields.password)
        .then((res) => {
          handleOpenSnackbar("success", t("password_changed"));
          const updatedUser = {
            email: user.email,
            password: fields.password,
          };
          setIsLoading(true);
          accountApi
            .login(updatedUser)
            .then((u) => {
              setUser(u.data);
              localStorage.setItem("jwt", JSON.stringify(u.data));
              setIsLoading(false);
              history.push("/");
            })
            .catch((err) => {
              try {
                console.log(err.response.data);
                if (err) {
                  handleOpenSnackbar("error", err.response.data);
                } else {
                  handleOpenSnackbar("error", t("generic_error"));
                }
                setIsLoading(false);
              } catch (err) {
                setIsLoading(false);

                console.log(err);
              }
            });
        })
        .catch((err) => {
          handleOpenSnackbar("error", t("password_change_failed"));
        });
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <Paper sx={{ padding: 2 }} elevation={3}>
            <BackButtonWithTitle title={t("change_password")} />
            <div className="item">
              <div>
                <TextField
                  variant="standard"
                  fullWidth
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  label={t("current_password")}
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                  helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  type="password"
                  id="password"
                  name="password"
                  label={t("new_password")}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t("repeat_new_password")}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
              </div>
              <FormButton type="submit" variant={"contained"}>
                {t("save")}
              </FormButton>
            </div>
          </Paper>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordLoggedIn;
