import { useState, useEffect, useContext } from "react";
import isEqual from "lodash/isEqual";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Button, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { timeDiff, scrollToTop, convertToUnixTimeStamp } from "../../common/utils/functions";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { InfoModal } from "./components/InfoModal";
import { EditTimeModal } from "./components/EditTimeModal";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { standardRequestsApi, requestsApi, settingsApi } from "../../api/api";
import { restrictedZonesApi } from "../../api/api";
import { CommentModal } from "./components/CommentModal";
import { ActiveTable, UpcomingTable, EarlierTable, ErasedTable, MapLayers } from "./components/AtsTables";
import { AtsMap } from "./components/AtsMap";
import { ActiveToolbar } from "./components/ActiveToolbar";
import { UpcomingToolbar } from "./components/UpcomingToolbar";
import { EarlierToolbar } from "./components/EarlierToolbar";
import { ErasedToolbar } from "./components/ErasedToolbar";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";

const Ats = ({ setSelected, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [pageSize, setPageSize] = useState(5);
  const [active, setActive] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [earlier, setEarlier] = useState([]);
  const [erased, setErased] = useState([]);
  const [standard, setStandard] = useState([]);
  const [restrictedZones, setRestrictedZones] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState();

  const [highlightActive, setHighlightActive] = useState(null);
  const [highlightUpcoming, setHighlightUpcoming] = useState(null);
  const [highlightEarlier, setHighlightEarlier] = useState(null);
  const [highlightErased, setHighlightErased] = useState(null);
  const [highlightStandardRequest, setHighlightStandardRequest] = useState(null);

  const [currPageActive, setCurrPageActive] = useState(1);
  const [currPageUpcoming, setCurrPageUpcoming] = useState(1);
  const [currPageEarlier, setCurrPageEarlier] = useState(1);
  const [currPageErased, setCurrPageErased] = useState(1);
  const [currPageStandard, setCurrPageStandard] = useState(1);

  const [totalPagesActive, setTotalPagesActive] = useState(1);
  const [totalPagesEarlier, setTotalPagesEarlier] = useState(1);
  const [totalPagesErased, setTotalPagesErased] = useState(1);
  const [totalPagesUpcoming, setTotalPagesUpcoming] = useState(1);
  const [totalPagesStandard, setTotalPagesStandard] = useState(1);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [previewZone, setPreviewZone] = useState();
  const [zones, setZones] = useState();
  const [toggleZones, setToggleZones] = useState(true);
  const [selectedUser, setSelectedUser] = useState();

  const [showComments, setShowComments] = useState(false);
  const [showRequestInfo, setShowRequestInfo] = useState(false);
  const [showRequestEdit, setShowRequestEdit] = useState(false);

  const [tab, setTab] = useState(0);

  const [searchActive, setSearchActive] = useState("");
  const [searchUpcoming, setSearchUpcoming] = useState("");
  const [searchEarlier, setSearchEarlier] = useState("");
  const [searchErased, setSearchErased] = useState("");

  const handleCloseComments = () => setShowComments(false);
  const handleShowComments = () => setShowComments(true);

  const handleCloseRequestInfo = () => setShowRequestInfo(false);
  const handleShowRequestInfo = () => setShowRequestInfo(true);

  const handleCloseRequestEdit = () => setShowRequestEdit(false);
  const handleShowRequestEdit = () => setShowRequestEdit(true);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Ats")) history.push("/unauthorized");
    else if (user.ats === null) {
      history.push("/noAts");
    }
    scrollToTop();
    return () => {
      // cleanup
      setSearchActive("");
      setSearchUpcoming("");
      setSearchEarlier("");
      setSearchErased("");
    };
  }, []);

  useEffect(() => {
    const retrieveAtsData = () => {
      setIsPageLoading(true);
      settingsApi
        .getPageSize()
        .then((res) => {
          setPageSize(res.data);

          Promise.all([
            requestsApi.getActiveByAts(1, res.data, user.ats.id),
            requestsApi.getUpcomingByAts(1, res.data, user.ats.id),
            requestsApi.getEarlierByAts(1, res.data, user.ats.id),
            requestsApi.getErasedByAts(1, res.data, user.ats.id),
            standardRequestsApi.getStandardByAts(1, res.data, user.ats.id),
            restrictedZonesApi.getRestrictedByAts(user.ats.id),
          ])
            .then(([act, upc, ear, era, sta, res]) => {
              const paginationActive = JSON.parse(act.headers.pagination);
              const paginationUpcoming = JSON.parse(upc.headers.pagination);
              const paginationEarlier = JSON.parse(ear.headers.pagination);
              const paginationErased = JSON.parse(era.headers.pagination);
              const paginationStandard = JSON.parse(sta.headers.pagination);

              setTotalPagesActive(paginationActive.TotalPages);
              setTotalPagesUpcoming(paginationUpcoming.TotalPages);
              setTotalPagesEarlier(paginationEarlier.TotalPages);
              setTotalPagesErased(paginationErased.TotalPages);
              setTotalPagesStandard(paginationStandard.TotalPages);

              setActive(act.data);
              setUpcoming(upc.data);
              setEarlier(ear.data);
              setErased(era.data);
              setStandard(sta.data);
              setRestrictedZones(res.data);

              setIsPageLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsPageLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    retrieveAtsData();
  }, []);

  useEffect(() => {
    let interv;
    if (user) {
      if (!showComments || !showRequestInfo || !showRequestEdit) {
        interv = setInterval(() => {
          requestsApi
            .getActiveByAts(currPageActive, pageSize, user.ats.id)
            .then((res) => {
              if (!isEqual(res.data, active)) {
                if (!searchActive) {
                  setTotalPagesActive(JSON.parse(res.headers.pagination).TotalPages);
                  setActive(res.data);
                }
              }
            })
            .catch((err) => console.log(err));
          requestsApi
            .getUpcomingByAts(currPageUpcoming, pageSize, user.ats.id)
            .then((res) => {
              if (!isEqual(res.data, upcoming)) {
                if (!searchUpcoming) {
                  setTotalPagesUpcoming(JSON.parse(res.headers.pagination).TotalPages);
                  setUpcoming(res.data);
                }
              }
            })
            .catch((err) => console.log(err));
          requestsApi
            .getEarlierByAts(currPageEarlier, pageSize, user.ats.id)
            .then((res) => {
              if (!isEqual(res.data, earlier)) {
                setTotalPagesEarlier(JSON.parse(res.headers.pagination).TotalPages);
                if (!searchEarlier) {
                  setEarlier(res.data);
                }
              }
            })
            .catch((err) => console.log(err));
          requestsApi
            .getErasedByAts(currPageEarlier, pageSize, user.ats.id)
            .then((res) => {
              if (!isEqual(res.data, erased)) {
                if (!searchErased) {
                  setTotalPagesErased(JSON.parse(res.headers.pagination).TotalPages);
                  setErased(res.data);
                }
              }
            })
            .catch((err) => console.log(err));
          standardRequestsApi
            .getStandardByAts(currPageStandard, pageSize, user.ats.id)
            .then((res) => {
              if (!isEqual(res.data, standard)) {
                setTotalPagesStandard(JSON.parse(res.headers.pagination).TotalPages);
                setStandard(res.data);
              }
            })
            .catch((err) => console.log(err));
          restrictedZonesApi
            .getRestrictedByAts(user.ats.id)
            .then((res) => {
              if (!isEqual(res.data, restrictedZones)) {
                setRestrictedZones(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }, 30000);
      }
    }
    return () => {
      // cleanup
      clearInterval(interv);
    };
  }, [user, selectedRequest, active, upcoming, earlier, erased, standard, restrictedZones]);

  const activateRequest = (request) => {
    setIsLoading(true);
    requestsApi
      .patchRequest(request.id, "/activatedDate", "replace", convertToUnixTimeStamp())
      .then((res) => {
        Promise.all([requestsApi.getActiveByAts(1, pageSize, user.ats.id), requestsApi.getUpcomingByAts(1, pageSize, user.ats.id)])
          .then(([act, upc]) => {
            setActive(act.data);
            setUpcoming(upc.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const endRequest = (request) => {
    setIsLoading(true);
    requestsApi
      .patchRequest(request.id, "/endedDate", "replace", convertToUnixTimeStamp())
      .then((res) => {
        requestsApi
          .patchRequest(request.id, "/flightTime", "replace", timeDiff(request.activatedDate))
          .then((res) => {
            Promise.all([requestsApi.getActiveByAts(1, pageSize, user.ats.id), requestsApi.getEarlierByAts(1, pageSize, user.ats.id)])
              .then(([act, ear]) => {
                setActive(act.data);
                setEarlier(ear.data);
                setHighlightActive();
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);

                setIsLoading(false);
              });
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onErase = (request) => {
    requestsApi.patchRequest(request.id, "/erased", "replace", true).then((res) => {
      requestsApi
        .getUpcomingByAts(1, pageSize, user.ats.id)
        .then((res) => {
          setUpcoming(res.data);
          setHighlightUpcoming();
          requestsApi.getErasedByAts(1, pageSize, user.ats.id).then((res) => {
            setErased(res.data);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onPostCopied = (request) => {
    setIsLoading(true);
    requestsApi
      .postCopiedRequest(request, convertToUnixTimeStamp())
      .then((res) => {
        requestsApi
          .getUpcomingByAts(currPageUpcoming, pageSize, user.ats.id)
          .then((res) => {
            const pagination = JSON.parse(res.headers.pagination);
            setTotalPagesUpcoming(pagination.TotalPages);
            setUpcoming(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);

            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log("Något gick fel", err);

        setIsLoading(false);
      });
  };

  const onHighlightActive = (e, request) => {
    if (!highlightActive) setHighlightActive(request);
    else {
      const current = [request.lat, request.lng];
      const earlier = [highlightActive.lat, highlightActive.lng];

      if (isEqual(current, earlier)) {
        setHighlightActive();
      } else {
        setHighlightActive(request);
      }
    }
  };
  const onHighlightUpcoming = (e, request) => {
    if (!highlightUpcoming) {
      setHighlightUpcoming(request);
    } else {
      const current = [request.lat, request.lng];
      const earlier = [highlightUpcoming.lat, highlightUpcoming.lng];

      if (isEqual(current, earlier)) {
        setHighlightUpcoming();
      } else {
        setHighlightUpcoming(request);
      }
    }
  };

  const onHighlightEarlier = (e, request) => {
    if (!highlightEarlier) setHighlightEarlier(request);
    else {
      const current = [request.lat, request.lng];
      const earlier = [highlightEarlier.lat, highlightEarlier.lng];

      if (isEqual(current, earlier)) {
        setHighlightEarlier();
      } else {
        setHighlightEarlier(request);
      }
    }
  };
  const onHighlightErased = (e, request) => {
    if (!highlightErased) setHighlightErased(request);
    else {
      const current = [request.lat, request.lng];
      const earlier = [highlightErased.lat, highlightErased.lng];

      if (isEqual(current, earlier)) {
        setHighlightErased();
      } else {
        setHighlightErased(request);
      }
    }
  };

  const onHighlightStandardRequest = (request) => {
    if (isEqual(request.area, previewZone)) {
      setPreviewZone();
      setHighlightStandardRequest();
    } else {
      setPreviewZone(request.area);
      setHighlightStandardRequest(request);
    }
  };

  const activateStandardRequest = (request) => {
    if (zones) {
      setZones([...zones, request]);
    } else setZones([request]);
  };

  const endStandardRequest = (request) => {
    const updatedList = zones.filter((zone) => zone.id !== request.id);
    setZones(updatedList);
  };

  const newPageActive = (p) => {
    setIsLoading(true);
    setCurrPageActive(p);
    if (!searchActive) {
      requestsApi
        .getActiveByAts(p, pageSize, user.ats.id)
        .then((res) => {
          setActive(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      requestsApi
        .searchActiveByAts(p, pageSize, user.ats.id, searchActive)
        .then((res) => {
          setActive(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageUpcoming = (p) => {
    setIsLoading(true);
    setCurrPageUpcoming(p);
    if (!searchUpcoming) {
      requestsApi
        .getUpcomingByAts(p, pageSize, user.ats.id)
        .then((res) => {
          setUpcoming(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      requestsApi
        .searchUpcomingByAts(p, pageSize, user.ats.id, searchUpcoming)
        .then((res) => {
          setUpcoming(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageEarlier = (p) => {
    setIsLoading(true);
    setCurrPageEarlier(p);
    if (!searchEarlier) {
      requestsApi
        .getEarlierByAts(p, pageSize, user.ats.id)
        .then((res) => {
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      requestsApi
        .searchEarlierByAts(p, pageSize, user.ats.id, searchEarlier)
        .then((res) => {
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageErased = (p) => {
    setIsLoading(true);
    setCurrPageErased(p);
    if (!searchErased) {
      requestsApi
        .getErasedByAts(p, pageSize, user.ats.id)
        .then((res) => {
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      requestsApi
        .searchErasedByAts(p, pageSize, user.ats.id, searchErased)
        .then((res) => {
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageStandard = (p) => {
    setCurrPageStandard(p);
    standardRequestsApi
      .getStandardByAts(p, pageSize, user.ats.id)
      .then((res) => {
        setStandard(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="standard-container">
      <div className="item">
        <CommentModal
          show={showComments}
          handleClose={handleCloseComments}
          selectedUser={selectedUser}
          user={user}
          setActive={setActive}
          setUpcoming={setUpcoming}
          setEarlier={setEarlier}
          setErased={setErased}
          setTotalPagesActive={setTotalPagesActive}
          setTotalPagesUpcoming={setTotalPagesUpcoming}
          setTotalPagesEarlier={setTotalPagesEarlier}
          setTotalPagesErased={setTotalPagesErased}
          pageSize={pageSize}
          setIsLoading={setIsLoading}
        />
        <InfoModal show={showRequestInfo} handleClose={handleCloseRequestInfo} selectedRequest={selectedRequest} />
        <EditTimeModal
          earlier={earlier}
          show={showRequestEdit}
          handleClose={handleCloseRequestEdit}
          selectedRequest={selectedRequest}
          handleOpenSnackbar={handleOpenSnackbar}
          setEarlier={setEarlier}
          setIsLoading={setIsLoading}
          currPageEarlier={currPageEarlier}
        />
        <div className="map-container">
          <AtsMap
            active={active}
            restrictedZones={restrictedZones}
            user={user}
            zones={zones}
            previewZone={previewZone}
            toggleZones={toggleZones}
            highlightActive={highlightActive}
            highlightUpcoming={highlightUpcoming}
            highlightEarlier={highlightEarlier}
            highlightErased={highlightErased}
          />
          <div style={{ marginTop: 4, marginBottom: 4 }}></div>
          <Button
            disabled={isLoading}
            variant="contained"
            color="info"
            sx={{ textTransform: "none" }}
            size="small"
            onClick={() => {
              toggleZones ? setToggleZones(false) : setToggleZones(true);
            }}
          >
            {t("show_hide_restricted_areas")}
          </Button>
        </div>
      </div>
      <div className="item">
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
            setHighlightActive();
            setHighlightUpcoming();
            setHighlightEarlier();
            setHighlightErased();
            setPreviewZone();
          }}
          aria-label="tabs"
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab label={t("active")} />
          <Tab label={t("upcoming")} />
          <Tab label={t("earlier")} />
          <Tab label={t("removed")} />
          <Tab label={t("maplayers")} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <ActiveToolbar
            setActive={setActive}
            setHighlightActive={setHighlightActive}
            search={searchActive}
            setSearch={setSearchActive}
            setIsLoading={setIsLoading}
            setCurrPageActive={setCurrPageActive}
            setTotalPagesActive={setTotalPagesActive}
            pageSize={pageSize}
          />
          <ActiveTable
            isLoading={isLoading}
            active={active}
            onHighlightActive={onHighlightActive}
            highlightActive={highlightActive}
            endRequest={endRequest}
            setSelectedUser={setSelectedUser}
            handleShowComments={handleShowComments}
            handleShowRequestInfo={handleShowRequestInfo}
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            setSelected={setSelected}
            history={history}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesActive}
            page={currPageActive}
            onChange={(e, newValue) => {
              newPageActive(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <UpcomingToolbar
            setUpcoming={setUpcoming}
            setHighlightUpcoming={setHighlightUpcoming}
            search={searchUpcoming}
            setSearch={setSearchUpcoming}
            setIsLoading={setIsLoading}
            setCurrPageUpcoming={setCurrPageUpcoming}
            setTotalPagesUpcoming={setTotalPagesUpcoming}
            pageSize={pageSize}
          />
          <UpcomingTable
            isLoading={isLoading}
            upcoming={upcoming}
            selectedRequest={selectedRequest}
            onHighlightUpcoming={onHighlightUpcoming}
            highlightUpcoming={highlightUpcoming}
            setHighlightUpcoming={setHighlightUpcoming}
            activateRequest={activateRequest}
            onErase={onErase}
            setSelectedUser={setSelectedUser}
            user={user}
            handleShowComments={handleShowComments}
            handleShowRequestInfo={handleShowRequestInfo}
            setSelectedRequest={setSelectedRequest}
            setSelected={setSelected}
            history={history}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesUpcoming}
            page={currPageUpcoming}
            onChange={(e, newValue) => {
              newPageUpcoming(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <EarlierToolbar
            setEarlier={setEarlier}
            setHighlightEarlier={setHighlightEarlier}
            search={searchEarlier}
            setSearch={setSearchEarlier}
            setIsLoading={setIsLoading}
            setCurrPageEarlier={setCurrPageEarlier}
            setTotalPagesEarlier={setTotalPagesEarlier}
            pageSize={pageSize}
          />
          <EarlierTable
            isLoading={isLoading}
            selectedRequest={selectedRequest}
            earlier={earlier}
            onHighlightEarlier={onHighlightEarlier}
            highlightEarlier={highlightEarlier}
            onPostCopied={onPostCopied}
            setSelectedUser={setSelectedUser}
            user={user}
            handleShowComments={handleShowComments}
            handleShowRequestInfo={handleShowRequestInfo}
            handleShowRequestEdit={handleShowRequestEdit}
            setSelectedRequest={setSelectedRequest}
            setSelected={setSelected}
            history={history}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesEarlier}
            page={currPageEarlier}
            onChange={(e, newValue) => {
              newPageEarlier(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <ErasedToolbar
            setErased={setErased}
            setHighlightErased={setHighlightErased}
            search={searchErased}
            setSearch={setSearchErased}
            setIsLoading={setIsLoading}
            setCurrPageErased={setCurrPageErased}
            setTotalPagesErased={setTotalPagesErased}
            pageSize={pageSize}
          />
          <ErasedTable
            isLoading={isLoading}
            selectedRequest={selectedRequest}
            erased={erased}
            onHighlightErased={onHighlightErased}
            highlightErased={highlightErased}
            onPostCopied={onPostCopied}
            setSelectedUser={setSelectedUser}
            handleShowComments={handleShowComments}
            handleShowRequestInfo={handleShowRequestInfo}
            setSelectedRequest={setSelectedRequest}
            setSelected={setSelected}
            history={history}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesErased}
            page={currPageErased}
            onChange={(e, newValue) => {
              newPageErased(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <MapLayers
            isLoading={isLoading}
            standard={standard}
            setSelectedUser={setSelectedUser}
            zones={zones}
            onHighlightStandardRequest={onHighlightStandardRequest}
            highlightStandardRequest={highlightStandardRequest}
            activateStandardRequest={activateStandardRequest}
            endStandardRequest={endStandardRequest}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesStandard}
            page={currPageStandard}
            onChange={(e, newValue) => {
              newPageStandard(newValue);
            }}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default Ats;
